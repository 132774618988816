export default {
  "header": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homepage"])},
    "corporate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corporate"])},
    "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
    "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
    "contact-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "human-res": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Human Resources"])},
    "who-are-we": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who Are We?"])},
    "about-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
    "patents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patents"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
    "hr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Human Resources"])},
    "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization"])}
  },
  "news": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest News"])},
    "hamle": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% Domestic 'CTD Probe' Production by STM and Bahçeşehir University!"])},
      "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STM and Bahçeşehir University to Produce 100% Domestic 'CTD Probe' System, Ending Foreign Dependency in Oceanographic Research"])},
      "content1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STM and Bahçeşehir University will develop ocean sensing systems, which are used to collect data on the physical properties of seawater in scientific research and military platforms, 100% domestically. With support from the LEAP program of the Ministry of Industry and Technology, foreign dependency in this field is aimed to be eliminated."])},
      "content2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STM Savunma Teknolojileri Mühendislik ve Ticaret A.Ş. has taken another critical step to bring domestic technologies to the Blue Homeland by developing progressive solutions in the Turkish defense industry within the scope of academia-industry cooperation."])},
      "content3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Oceanographic Sensing Devices Project of STM and BAU Inovasyon ve Danışmanlık A.Ş. (BAUMIND), a subsidiary of Bahçeşehir University; was entitled to be supported with domestic and national means in the frame of Technology-Oriented Industrial Move (HAMLE) Program of Republic of Türkiye Ministry of Industry and Technology."])},
      "content4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The partners will develop an advanced CTD Probe, and expendable oceanographic devices with domestically developed sensors to collect oceanographic data.CTD Probes calculate salinity, water density and underwater speed of sound by measuring the conductivity, temperature, and pressure of seawater. The other single-use systems are expendable temperature and sound speed measurement devices, which collect temperature and sound speed in terms of depth"])},
      "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devices will Provide Data to Sonars, the Eyes and Ears of Submarines"])},
      "content5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The primary objective of the program is to reduce Türkiye’s foreign dependency by developing local and national technologies in ocean science, while also enhancing the operational capabilities of submarines."])},
      "content6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These systems play a critical role in ensuring that the sonar systems of submarines—the eyes and ears of the underwater platforms—make accurate measurements. Equipped with precise sensors, the CTD Probe will measure the conductivity, temperature, and pressure of seawater to finally calculate sound speed along with salinity and water density. The acquired data is then used to chart the propagation pattern of underwater sound, allowing the performance modeling of sonar systems."])},
      "content7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These critical systems that are developed nationally are expected to be used in many national and international projects, especially in the ones that the Turkish Naval Forces Command has planned to realize in the future, as well as in scientific research activities."])},
      "title4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güleryüz: Türkiye to Support Underwater Studies with Modern and Local Systems"])},
      "content8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özgür Güleryüz, General Manager of STM, underlined that R&D and academia-industry collaboration has been an institutional culture at STM, saying, Being a key player in the Turkish Defense Industry, it is a responsibility for us to invest on R&D of systems not produced in Turkey in collaboration with universities. In the past, we developed the CTD Probe System jointly with Bahçeşehir University making maximum use of national capabilities in the submarine technologies field. Now, we shall also develop expendable devices measuring temperature and sound speed profiles as well as the sensors of the CTD Probe system."])},
      "content9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noting the origin of critical systems creating foreign dependence on platform projects, Güleryüz continued:"])},
      "content10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In case of restrictions and embargoes, the schedule of projects may be affected adversely. For this reason, we are concentrating our effort in R&D on critical systems that are not produced domestically. The oceanographic devices to be developed under the project will be used not only in the military field but also in many other areas, such as civil maritime operations and environmental studies. We will thus be contributing to studies such as those assessing underwater resources in the Blue Homeland, determining the physical properties of seawater, and underwater mapping using systems with a high localization rate. I send my congratulations to the esteemed teams from STM and BAU who have contributed to this project.”"])}
    }
  },
  "footer": {
    "bau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BAU Innovation and Consultancy Inc."])},
    "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Rights Reserved"])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BAU Innovation and Consultancy Inc."])},
    "slider": {
      "slide1Tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CO-DIVER"])},
      "slide1Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Underwater Optical Communication"])},
      "slide2Tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CO-LIGHTING"])},
      "slide2Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Underwater Lighting Device"])},
      "slide3Tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
      "slide3Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submarine-Helicopter Communication"])},
      "slide4Tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
      "slide4Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Underwater Optical Spectrum Meter"])},
      "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More Information"])}
    },
    "grid": {
      "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An Underwater Analyzer for Analyzing Water Absorption Ability"])},
      "tag1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optics"])},
      "tag1_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patent"])},
      "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wireless Image Transmission Device for Optical Sight Weapons"])},
      "tag2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optics"])},
      "tag2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patent"])},
      "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maritime Optical Communication Device"])},
      "subtitle3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coLink is a communication system produced to provide information security and to establish quality communication maritime."])},
      "title4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innovative Technological Generation Training Kit"])},
      "subtitle4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YETENEK-12 is a training kit developed within the scope of TÜBİTAK 1511 Priority Areas Research Technology Development and Innovation Projects Support Program."])},
      "title5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Underwater Lighting Device"])},
      "tag5_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lighting"])},
      "title6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self Acoustic Estimation and Acoustic Monitoring/Warning System in Submarines"])},
      "tag6_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acoustic"])},
      "tag6_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])},
      "exploreButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore Now"])}
    },
    "latestPatents": {
      "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Research & Development"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest Patents"])},
      "patent1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Underwater electro-optical communication system"])},
      "patent1-tag1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Underwater"])},
      "patent1-tag2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optics"])},
      "patent2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wireless Image Transmission Device for Optical Sight Weapons"])},
      "patent2-tag1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optics"])},
      "patent2-tag2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communication"])},
      "patent3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An Underwater Analyzer for Analyzing the Absorption Ability of Water"])},
      "patent3-tag1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Underwater"])},
      "patent3-tag2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyzer"])},
      "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More Information"])},
      "seemore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See More Patents"])}
    },
    "seemore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See More Moments"])}
  },
  "aboutus": {
    "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Company"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
    "whoarewe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who Are We?"])},
    "whoarewe-content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BAUMIND (Bahçeşehir University Innovation & Consultancy Inc.); In the first stage, it started its activities as a Technology Development Unit with in Bahçeşehir University in 2008.Afterwards, in 2012, he took the name of Project Development Unit and carried out the Technology Acquisition project supported by the Undersecretariat of Defense Industry called Safe Electro Optical Communication System Between Submarine Helicopters with a budget of 1.M Euros."])},
    "whoarewe-content-long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BAUMIND (Bahçeşehir University Innovation & Consultancy Inc.); In the first stage, it started its activities in 2008 as a Technology Development Unit within Bahçeşehir University. Afterwards, in 2012, he took the name of Project Development Unit and carried out the Technology Acquisition project supported by the Undersecretariat of Defense Industry named “Safe Electro Optical Communication System Between Submarine Helicopters” with a budget of 1.1 M Euros. For the first time in 2016, BAU A.Ş. took its name and moved its activities to the Teknopark Istanbul location. In 2016, he carried out the Technology Acquisition project in partnership with ITU Nova, supported by another Undersecretariat for Defense Industries. In 2018, he left Teknopark Istanbul to apply for an R&D Center and moved to its current building in Nakkaştepe Campus. It carries out 2 completed and 2 ongoing TÜBİTAK Projects since 2018. BAU Inc. With 23 personnel, 8 different departments, 4 completed, 2 ongoing projects, 4 prototypes and 10 patents, it continues to work to become a leader in its field to meet Turkey's technological needs in the military and civil fields."])},
    "aims": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Aims"])},
    "aims-content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkey's military and military security and honestly; First of all, to have a business competency in the electro- and science field, in terms of technology and health solutions, a recent competition."])},
    "vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vision & Mission"])},
    "vision-content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In pursuit of new technologies, to be a leading institution with domestic and international competence that produces information and technology in the field of electro-optics in line with Turkey's technological needs and strategic goals in military and civil fields."])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
    "history1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2005-2012 Bahçeşehir University Project Development Unit – Beşiktaş / ISTANBUL"])},
    "history2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2012-2016 Bahçeşehir University R&D and Innovation Projects Coordinator- Beşiktaş / İSTANBUL"])},
    "history3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2016-2022 BAU Innovation and Consulting Inc. Uskudar/ İSTANBUL"])},
    "history4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022-Present Bahçeşehir University Education Building Eyüpsultan/ İSTANBUL"])},
    "aims1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To be one of the competitive companies both domestic and abroad that produce information, technology and innovative solutions, in line with the Turkey’s demands in military/commercial markets, primarily within the scope of electro-optics and acoustics."])},
    "aims2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To execute hi-tech R&D and product development activities in order to help technological independence of Turkey, while establishing strategical partnership with the universities and engineering firms for the creation of innovative solutions in the field of electro-optics and acoustics."])},
    "aims3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In addition to provide consultancy-training services to the institutions and firms in the market, licensing and technology transfer of our innovative projects, products-solutions for both civilian and military use."])},
    "patent": {
      "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Research & Development"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patents"])},
      "patent1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Underwater electro-optic communication system"])},
      "patent2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Underwater optical acoustic composite trap system"])},
      "patent3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wireless Image Transmission Device in Optical Aiming Firearms"])},
      "patent4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Rubber Wear Control System"])},
      "patent5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Underwater Lighting Equipment"])},
      "patent6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Underwater Analysis Device for Analyzing Water Absorption Capability"])},
      "patent7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lighting Equipment Suitable for Illuminating Underwater"])},
      "patent8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An Underwater Communication Device"])},
      "patent9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communication Device Between Underwater Platforms"])},
      "patent10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modular Electro-Optic Communication Device"])},
      "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More Information"])}
    }
  },
  "products": {
    "scroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUST SCROLL"])},
    "more-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More Information"])},
    "colighting": {
      "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UNDERSEA"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coLighting is a lighting system that offers innovative solutions in its technological field."])}
    },
    "codiver": {
      "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OPTICS"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coDiver is a communication system produced to provide information security and to establish quality communication underwater."])}
    },
    "colink": {
      "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OPTICS"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coLink is a communication system produced to provide information security and to establish quality communication maritime."])}
    },
    "yetenek": {
      "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EDUCATION"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YETENEK-12 is a education kit developed within the scope of TÜBİTAK 1511 Priority Areas Research, Technology Development and Innovation Projects Support Program."])}
    }
  },
  "projects": {
    "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Research & Development"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
    "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More Information"])},
    "project1": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Underwater Optical Spectrum Meter"])},
      "tag1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optics"])},
      "tag2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Underwater"])}
    },
    "project2": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self Acoustic Estimation and Acoustic Monitoring/Warning System in Submarines"])},
      "tag1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acoustic"])},
      "tag2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Underwater"])}
    },
    "project3": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secure Optical Communication System Between Submarine and Helicopter"])},
      "tag1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optics"])},
      "tag2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communication"])}
    }
  },
  "contactus": {
    "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Company"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Form"])},
    "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send a Message"])},
    "formEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "formName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "formMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "fulladdress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mithatpaşa Mah. Rumeli Yolu Cad. Bahçeşehir Üniversitesi Eğitim Binası Blok No: 35/24 İç Kapı No: 6 Eyüpsultan/İSTANBUL"])}
  },
  "error": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404 Page"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The page you are looking for does not exist. It might have been moved or deleted."])},
    "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back To Home"])}
  },
  "comingsoon": {
    "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almost Here"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coming Soon"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The page you are looking for is not available yet. Please check back later."])},
    "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go Back"])}
  },
  "about": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BAUMIND (Bahçeşehir University Innovation and Consultancy) Inc.; started its activities in 2008 under the roof of Bahçeşehir University as a research and development unit in the fields of optics, electro-optics, acoustics and information technologies, mainly in the field of defense industry."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It works towards becoming a leading company that produces innovative solutions in line with Turkey's technological needs in military and civilian fields and strategic goals, and has the competence to compete both domestically and internationally."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to produce innovative solutions that will contribute to reducing external dependency, it conducts research, innovation and product development activities that include advanced technology applications by establishing strategic partnerships with universities and other engineering companies."])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BAUMIND aims to transfer technology and license the outputs of the innovative projects that have results suitable for use either in public or civilian fields. She also provides consultancy and training services in her areas of expertise to institutions and companies in the sector."])}
  },
  "misyon": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To be a research and development company that primarily focuses on Defense Industry, as well as military/civilian applications in the fields of Optical, Electro-Optical, Acoustic, and Information Technologies, by creating original, innovative, and sustainable projects, providing solutions, and producing products."])}
  },
  "vizyon": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To become a leading company with competence in generating knowledge and technology both domestically and internationally, in line with our country's technological needs and strategic objectives."])}
  },
  "organization": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization"])}
  },
  "teams": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senior Management & Organization"])},
    "niltitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chairman of the Board, BAUMIND"])},
    "niltitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Manager, BUG Lab TEKMER"])},
    "niltitle3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Director, BAUTTO"])},
    "ferhattitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Manager"])},
    "selcuktitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["R&D Director"])},
    "bulenttitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deputy General Manager"])},
    "suleymantitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Integration Specialist"])},
    "hakantitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manufacturing Manager"])},
    "eraytitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procurement Engineering"])},
    "saidtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Embedded Software Engineer"])},
    "aslititle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senior R&D Engineer"])},
    "fatihtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAD/CAM Designer"])},
    "alpertitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CNC Operator"])}
  },
  "project1": {
    "arastirma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Research & Development"])},
    "projeler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Underwater Optical Spectrum Measurement Device"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Examining the hydrocarbon distribution of water"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Examining the algal capacity of water (bioactivity)"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Examining the plankton capacity of water"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Examination of soil particles in water in terms of erosion,"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Methane examination (bioactivity, examination of fault lines)"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Examination of water quality"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precise examination of salinity depending on salt type"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating optical conductivity maps in Turkish seas"])},
    "amac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The goal of the project; It is the measurement of the optical conductivity of sea or lake waters depending on the wavelength. By analyzing the graph obtained as a result of this measurement, the information it can provide us and the areas where it can be used are:"])}
  },
  "project2": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self-Acoustic Estimation and Acoustic Monitoring/Warning System in Submarines"])},
    "baslik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SELF ACOUSTIC ESTIMATION AND ACOUSTIC MONITORING/WARNING SYSTEM IN SUBMARINES"])},
    "amac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The systems subject to the project are a wireless communication technology, and sound transmission is provided between two platforms with light beams and high-frequency sound waves (ultrasonic) instead of radio waves. In these systems, high-frequency modulated LED or laser light sources are used as light sources. Electro-optic Since systems are inefficient in cases where visibility is insufficient and weather conditions such as fog etc., the system will provide communication through acoustic communication in such cases."])},
    "bilgi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presidency of Defense Industries and Anadolu Deniz İnşaat Kızakları A.Ş. Within the scope of the Amphibious Ship (LST) Supply Contract signed in March 2016, command and control software development and Sensor design-manufacturing was successfully completed in December 2018 and delivered to İTÜNOVA."])}
  },
  "project4": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secure Optical Communication System Between Submarine and Helicopter"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communication range: 74.5 meters (testable 22.5 m underwater, 52 m above water)"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It communicates optically via LED."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provides directed communication"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provides directed communication"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EMI and RF interference resistance"])},
    "head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECURE ELECTRO-OPTICAL COMMUNICATION SYSTEM BETWEEN SUBMARINE SHIP AND HELICOPTER"])},
    "amac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presidency of Defense Industries and Istanbul Maritime Shipbuilding Industry Inc. Within the scope of the Seismic Research Ship Construction Project signed in April 2012, the Secure Optical Communication System Between Submarine and Helicopter (DEHEGOPHS) project, which was contracted under the title of Technology Acquisition Project (August 2012), was successfully completed in 2015."])},
    "head1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(DEHEGOPHS)"])}
  },
  "colight": {
    "giris": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coLighting is a lighting system that offers innovative solutions in its technological field. coLighting is a system that automatically adjusts the RGB light intensity by measuring the wavelength-based attenuation in the operational aquatic environment."])},
    "teknikbaslik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical Specifications"])},
    "teknik1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It detects the missing spectrum and automatically adjusts the required wavelengths to provide clarity"])},
    "teknik2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It fiils all visible wavelengths in the LED spectrum with RGB LEDs"])},
    "teknik3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The wavelength is in the range of 400nm-870nm"])},
    "teknik4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It adjusts by measuring the level of white light in the water"])},
    "teknik5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Since the system works linearly(LED drive),it elimantes the shading problem"])},
    "teknik6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It provides clearer and more detailed images by preventing white light spot"])},
    "part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical Parts"])},
    "part1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aluminium Design"])},
    "part2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color Sensors"])},
    "part3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Battery Unit"])}
  },
  "codiver": {
    "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OPTIK"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coDiver is a communication system produced to provide information security and to establish quality communication underwater. LEDs (Light emitting diode) placed on the Electro-optic transmitter were used as the light source in the device, which works according to the electro-optic principles, and the detector was used in the Electro-optic receiver."])},
    "giris": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coDiver is a communication system produced to provide information security and to establish quality communication underwater. LEDs (Light emitting diode) placed on the Electro-optic transmitter were used as the light source in the device, which works according to the electro-optic principles, and the detector was used in the Electro-optic receiver. The most important feature of the device according to current applications is that the signal emanating from the source can only reach the target in the form of a beam. Thus, long distance, uninterrupted and, secure communication is provided between the users."])},
    "teknik1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impermeability : 60 Meters"])},
    "teknik2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation Time : Full-Duplex 5 hours (With 2 hours charge)"])},
    "teknik2h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Half-Duplex 5 hours (With 2 hours charge)"])},
    "teknik3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transmitter Type : LED"])},
    "teknik4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montage : Hand-held"])},
    "teknik5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power Capacity : 6.000 mAh"])},
    "teknik6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight : 2Kg"])},
    "teknik7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storage Conditions : -5 °C/ +45 °C"])},
    "teknik8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working Conditions : 0 °C/ +45 °C"])},
    "teknik9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unpredictable, Unlistenable and Unmistakable communication opportunity"])},
    "teknik10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produced 75% locally and nationally from materials that are not subject to Export License"])}
  },
  "colink": {
    "giris": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Designed for use in atmospheric environments, coLink provides information security in communication with eye-safe electro-optical technology. It is used for hunters, researchers and observers in all outdoor activities, use with a tripod on fixed platforms, secure communication in secret operations, communication between special forces personnel, and mobile voice/data communication between fixed military platforms."])},
    "teknikbaslik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technicial Specifications"])},
    "teknik1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full and Half Duplex Voice Communication Opportunity"])},
    "teknik2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP23 sealing"])},
    "teknik3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resistant to MIL-STD-810G Method 501.5 (I-II), Method 502.5 (I-II), Method 507.5 (II) environmental conditions"])},
    "teknik4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MIL-STD-461G RE102 (b/c), RE103 (a/b) EMI EMC resistant"])},
    "teknik5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 hours of operational use with 2 hours of charging in Full Duplex communication mode"])},
    "teknik6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8 hours of operational use with 2 hours of charging in Half Duplex communication mode"])},
    "teknik7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6,000mAh external battery unit"])},
    "teknik8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1400 meters operational communication distance"])},
    "teknik9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unpredictable, Unlistenable and Unmistakable communication opportunity"])},
    "teknik10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produced 75% locally and nationally from materials that are not subject to Export License"])}
  },
  "button": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Come Back"])}
  },
  "linkedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click for our LinkedIn Profile"])},
  "product": {
    "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Research & Development"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
    "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More Information"])},
    "product1": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Underwater Lighting Equipment coLigthing"])},
      "tag1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optical"])},
      "tag2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Underwater"])}
    },
    "product2": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Underwater Communication Device coDiver"])},
      "tag1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acoustic"])},
      "tag2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Underwater"])}
    },
    "product3": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atmospheric Electro-Optic Communication System coDatalink"])},
      "tag1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optical"])},
      "tag2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communication"])}
    },
    "product4": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Underwater Analyzer for Water Absorption Capability coSpectro"])},
      "tag1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optical"])},
      "tag2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technology"])}
    },
    "product5": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modular Electro-Optic Communication Device coLink"])},
      "tag1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optical"])},
      "tag2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technology"])}
    },
    "product6": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innovative Technological Generation Education Kit Ability-12"])},
      "tag1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])},
      "tag2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technology"])}
    }
  }
}