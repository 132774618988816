export default {
  "header": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ana Sayfa"])},
    "corporate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurumsal"])},
    "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ürünler"])},
    "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projeler"])},
    "contact-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bize Ulaşın"])},
    "human-res": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İnsan Kaynakları"])},
    "who-are-we": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biz Kimiz?"])},
    "about-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hakkımızda"])},
    "patents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patentler"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haberler"])},
    "hr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İnsan Kaynakları"])},
    "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizasyon"])}
  },
  "news": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haberler"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haberler ve Duyurular"])},
    "hamle": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STM ve Bahçeşehir Üniversitesi'nden Yüzde 100 Yerli 'CTD Prob' Üretimi!"])},
      "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STM ve Bahçeşehir Üniversitesi'nden Yüzde 100 Yerli 'CTD Prob' Üretimi: Oşinografik Araştırmalarda Dışa Bağımlılığa Son"])},
      "content1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STM ve Bahçeşehir Üniversitesi, oşinografik araştırmalarda ve askeri platformlarda deniz suyunun fiziksel özelliklerine ilişkin veri toplamak amacıyla kullanılan “CTD Prob” sistemlerini  yüzde 100 yerli olarak üretecek. Sanayi ve Teknoloji Bakanlığı’nca HAMLE programı çerçevesinde desteklenmeye hak kazanan proje ile bu alandaki dışa bağımlılığa son verilecek."])},
      "content2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Türk savunma sanayiinde ilerici çözümler geliştiren STM Savunma Teknolojileri Mühendislik ve Ticaret A.Ş., üniversite-sanayii iş birliği kapsamında, Mavi Vatan’a milli teknolojiler kazandıracak kritik bir adım daha attı."])},
      "content3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Türkiye’de ilk kez yerli ve milli imkanlarla, STM ve Bahçeşehir Üniversitesi’nin iştiraki olan BAU İnovasyon ve Danışmanlık A.Ş. (BAUMIND) tarafından geliştirilen, “CTD Prob” sistemi, T.C. Sanayi ve Teknoloji Bakanlığı’nın Teknoloji Odaklı Sanayi Hamlesi (HAMLE) programı çerçevesinde desteklenmeye hak kazandı."])},
      "content4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program kapsamında, yüzde 100 yerli sensörlere sahip gelişmiş CTD Prob, tek kullanımlık sıcaklık ve ses hızı ölçüm ekipmanlarının geliştirilmesi hedefleniyor. İletkenlik sensörü yabancı menşeli CTD Prob ürünü, 2022 yılında STM-BAUMIND iş birliği çerçevesinde geliştirilmiş ve tüm testleri başarıyla tamamlamıştı.  Bahse konu ürün, HAMLE Programı ile sağlanan destek sayesinde; sensörleri de içerecek şekilde tamamen yerli bir statüye kavuşacak ve Türkiye’de bir ilke imza atılacak."])},
      "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denizaltıların Gözü Kulağı Olan Sonarlara Veri Sağlıyor"])},
      "content5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deniz suyunun iletkenliğini, sıcaklığını ve basıncını ölçen CTD Problar, su altındaki ses hızını hesaplıyor. Tek kullanımlık sıcaklık ve ses hızı ölçüm cihazları da, derinliğe nazaran sıcaklık ve ses hızı profillerini çıkartarak SONAR sistemleri için gerekli verileri sağlayan, sarf edilebilir sistemler arasında yer alıyor."])},
      "content6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oşinografi alanında yerli ve milli teknolojiler geliştirilerek dışa bağımlılığı azaltacak proje ile denizaltıların operasyonel kabiliyetlerinin de geliştirilmesi planlanıyor. Söz konusu sistemler, denizaltıların sualtında gözü kulağı olan sonar sistemlerinin doğru ölçüm yapması için kritik bir işlev görüyor. Sahip olduğu hassas sensörlerle, deniz suyunun iletkenliğini, sıcaklığını ve basıncı ölçen CTD Prob, ses hızının değerini ortaya koyuyor. Sesin su altındaki yayılım paterninin oluşturulmasında kullanılan CTD verileri sayesinde sonar sistemlerinin performans modellemeleri yapılabiliyor."])},
      "content7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yerli olarak geliştirilen bu kritik sistemlerin; Türk Deniz Kuvvetleri Komutanlığı’nın yakın gelecekte gerçekleştirmeyi planladığı projeler başta olmak üzere, bilimsel araştırma faaliyetleri de dahil birçok yurt içi ve yurt dışı projede değerlendirilmesi bekleniyor."])},
      "title4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güleryüz: Mavi Vatan’da Sualtı Çalışmaları Yerli Sistemlerle Yürütülecek"])},
      "content8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STM Genel Müdürü Özgür Güleryüz, STM’de, Ar-Ge ve üniversite-sanayii iş birliklerinin bir kurum kültürü olduğuna belirterek, “Türk savunma sanayii olarak, üniversitelerimiz ile ortak çalışmalar yürüterek, Türkiye’de üretimi olmayan sistemleri milli imkanlarla geliştirmeyi de bir vazife olarak görüyoruz. Denizaltı teknolojilerinde, Bahçeşehir Üniversitesi ile ortaklaşa geliştirdiğimiz CTD Prob Sistemini geçtiğimiz yıllarda azami milli imkanlarla geliştirmiştik. Yüzde 60’ın üzerindeki yerlilik oranına sahip CTD Prob sisteminin sensörlerini, sonar sistemlerinin performansında çalışması için gerekli olan derinliğe nazaran deniz suyu sıcaklık ve ses hızı profillerini çıkartan tek kullanımlık cihazları da  artık milli olarak geliştireceğiz” ifadelerine yer verdi."])},
      "content9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Günümüzde, kritik önemi haiz sistemlerin yurtdışı menşeli olmasının platform projelerinde bağımlılık yarattığına dikkat çeken Güleryüz şöyle devam etti:"])},
      "content10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Kısıtlama ve ambargolara maruz kalınması durumunda projelerin takvimi olumsuz etkilenebiliyor. Bu sebeple üretimi olmayan ve yurt dışına bağımlı olduğumuz kritik sistemlere odaklanıyoruz. Proje ile geliştirilmesi planlanan oşinografi cihazları, sadece askeri alanda değil, sivil denizcilik ve çevre araştırmaları gibi birçok alanda da kullanılabilecek. Bu sayede, Mavi Vatan’da sualtı kaynaklarının araştırılması, deniz suyunun fiziksel özelliklerinin belirlenmesi ve deniz altı haritalaması gibi çalışmalara yerlilik oranı yüksek sistemlerle katkı sağlamış olacağız. Projede emeği geçen kıymetli STM VE BAU ekiplerini tebrik ediyorum.”"])}
    }
  },
  "footer": {
    "bau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BAU İnovasyon ve Danışmanlık A.Ş."])},
    "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm Hakları Saklıdır"])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BAU İnovasyon ve Danışmanlık A.Ş."])},
    "slider": {
      "slide1Tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CO-DIVER"])},
      "slide1Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su Altı Optik Muhabere Cihazı"])},
      "slide2Tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CO-LIGHTING"])},
      "slide2Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su Altı Aydınlatma Cihazı"])},
      "slide3Tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projeler"])},
      "slide3Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denizaltı-Helikopter Haberleşme Sistemi"])},
      "slide4Tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projeler"])},
      "slide4Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su Altı Optik Spektrum Ölçüm Cihazı"])},
      "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha Fazla"])}
    },
    "grid": {
      "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sualtı Optik Spektrum Ölçüm Cihazı"])},
      "tag1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optik"])},
      "tag1_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patent"])},
      "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optik Nişangâhlı Silahlarda Kablosuz Görüntü Aktarma Cihazı"])},
      "tag2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optik"])},
      "tag2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patent"])},
      "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su Üstü Optik Muharebe Cihazı"])},
      "subtitle3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coLink, su üstü alanlarda bilgi güvenliği sağlamak ve kaliteli iletişim kurabilmek amacıyla üretilmiş bir haberleşme sistemidir."])},
      "title4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yenilikçi Teknolojik Nesil Eğitim Kiti"])},
      "subtitle4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YETENEK-12, TÜBİTAK 1511 Öncelikli Alanlar Araştırma Teknoloji Geliştirme ve Yenilik Projeleri Destekleme Programı kapsamında geliştirilmiş bir eğitim kitidir."])},
      "title5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su Altı Aydınlatma Cihazı"])},
      "tag5_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aydınlatma"])},
      "tag5_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ürün"])},
      "title6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denizaltılarda Öz Akustik Kestirimi Ve Akustik İzleme/Uyarı Sistemi"])},
      "tag6_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akustik"])},
      "tag6_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proje"])},
      "exploreButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şimdi Keşfet"])}
    },
    "latestPatents": {
      "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Araştırma & Geliştirme"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son Patentler"])},
      "patent1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sualtı elektro-optik haberleşme sistemi"])},
      "patent1-tag1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su altı"])},
      "patent1-tag2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optik"])},
      "patent2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optik Nişangâhlı Silahlarda Kablosuz Görüntü Aktarma Cihazı"])},
      "patent2-tag1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optik"])},
      "patent2-tag2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haberleşme"])},
      "patent3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suyun Soğurma Kabiliyetini Analiz Etmek İçin Bir Sualtı Analiz Cihazı"])},
      "patent3-tag1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su altı"])},
      "patent3-tag2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiz"])},
      "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha Fazla"])},
      "seemore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm Patentler"])}
    }
  },
  "aboutus": {
    "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şirketimiz"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hakkımızda"])},
    "whoarewe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biz Kimiz?"])},
    "whoarewe-content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BAU A.Ş.; İlk etapta 2008 yılında Bahçeşehir Üniversitesi bünyesinde Teknoloji Geliştirme Birimi olarak faaliyetlerine başlamıştır. Sonrasında 2012 yılında ise Proje Geliştirme Birimi ismini alarak 1.M Euro bütçe ile “Denizaltı Helikopter Arasında Güvenli Elektro Optik Haberleşme Sistemi” adlıSavunma Sanayi Müsteşarlığı tarafından desteklenen Teknoloji Kazanım projesini yürüttü."])},
    "whoarewe-content-long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BAU A.Ş.; ilk etapta 2008 yılında Bahçeşehir Üniversitesi bünyesinde Teknoloji Geliştirme Birimi olarak faaliyetlerine başladı. Sonrasında 2012 yılında ise Proje Geliştirme Birimi ismini alarak 1.1 M Euro bütçe ile “Denizaltı Helikopter Arasında Güvenli Elektro Optik Haberleşme Sistemi” adlı Savunma Sanayi Müsteşarlığı tarafından desteklenen Teknoloji Kazanım projesini yürüttü. 2016 yılında ilk defa BAU A.Ş. adını alarak faaliyetlerini Teknopark İstanbul mevkiine taşıdı. 2016 senesinde başka bir Savunma Sanayi Müsteşarlığı tarafından desteklenen İTÜ Nova ortaklığı ile Teknoloji Kazanım projesini yürüttü. 2018 yılında Ar-Ge Merkezi başvurusu yapmak için Teknopark İstanbul’dan ayrılarak Nakkaştepe Yerleşkesinde bulunan şimdiki binasına taşındı. 2018 yılından bu yana yapılan 2 tamamlanmış 2 devam etmekte olan TÜBİTAK Projelerini yürütmektedir. BAU A.Ş. 23 personel, 8 farklı bölüm, 4 tamamlanmış 2 devam etmekte olan proje 4 prototip ve 10 adet patent ile Türkiye’nin askeri ve sivil alanda teknolojik ihtiyaçlarını karşılamak için alanında lider olmak için çalışmalarına devam etmektedir."])},
    "aims": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hedeflerimiz"])},
    "aims-content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Türkiye’nin askeri ve sivil alanlarda teknolojik ihtiyaçları ve stratejik hedefleri doğrultusunda; öncelikli olarak elektro-optik ve akustik alanında bilgi, teknoloji ve yenilikçi çözümler üreten, yurtiçinin yanı sıra yurtdışında da rekabet edebilecek yetkinliğe sahip lider bir firma olmak."])},
    "vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizyon & Misyon"])},
    "vision-content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni teknolojilerin peşinde Türkiye’ nin askeri ve sivil alanlarda teknolojik ihtiyaçları ve stratejik hedefleri doğrultusunda elektro-optik alanında bilgi ve teknoloji üreten yurtiçi ve yurtdışında yetkinliğe sahip lider bir kurum olmak."])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarihçe"])},
    "history1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2005-2012 Bahçeşehir Üniversitesi Proje Geliştirme Birimi – Beşiktaş / İSTANBUL"])},
    "history2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2012-2016 Bahçeşehir Üniversitesi ARGE ve İnovasyon Projeleri Koordinatörlüğü- Beşiktaş / İSTANBUL"])},
    "history3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2016-2022 BAU İnovasyon ve Danışmanlık A.Ş. Üsküdar/ İSTANBUL"])},
    "history4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022-Halen Bahçeşehir Üniversitesi Eğitim Binası Eyüpsultan/ İSTANBUL"])},
    "aims1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Türkiye’nin askeri ve sivil alanlarda teknolojik ihtiyaçları ve stratejik hedefleri doğrultusunda; öncelikli olarak elektro-optik ve akustik alanında bilgi, teknoloji ve yenilikçi çözümler üreten, yurtiçinin yanı sıra yurtdışında da rekabet edebilecek yetkinliğe sahip lider bir firma olmak."])},
    "aims2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Türkiye’nin teknolojik alanda dış ülkelere bağlılığını azaltmada katkıda bulunacak nitelikte, elektro-optik ve akustik alanında yenilikçi çözümler üretmek amacıyla, üniversiteler ve diğer mühendislik firmaları ile stratejik ortaklıklar kurarak, ileri teknolojileri uygulamaları içeren ARGE ve ürün geliştirme faaliyetlerini yürütmek."])},
    "aims3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Askeri ve sivil çift kullanıma uygun yenilikçi proje ve ürünlerimizi-çözümlerimizi ilgili sektörlere lisanslamak/teknolojiyi transfer etmek, bunlara ek olarak sektördeki kurum ve firmalara uzmanlık alanlarımızda danışmanlık-eğitim hizmeti sunmaktır."])},
    "patent": {
      "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Araştırma & Geliştirme"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patentler"])},
      "patent1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sualtı elektro-optik haberleşme sistemi"])},
      "patent2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su altı optik akustik kompozit tuzak sistemi"])},
      "patent3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optik Nişangâhlı Silahlarda Kablosuz Görüntü Aktarma Cihazı"])},
      "patent4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir Lastik Aşınma Kontrol Sistemi"])},
      "patent5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sualtı Aydınlatma Tertibatı"])},
      "patent6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suyun Soğurma Kabiliyetini Analiz Etmek İçin Bir Sualtı Analiz Cihazı"])},
      "patent7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su Altında Aydınlatma Yapmaya Uygun Bir Aydınlatma Tertibatı"])},
      "patent8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir Su Altı Haberleşme Cihazı"])},
      "patent9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sualtı Platformları Arasında Haberleşme Cihazı"])},
      "patent10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modüler Bir Elektro Optik Haberleşme Cihazı"])},
      "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha Fazla Bilgi"])}
    }
  },
  "button": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri Dön"])}
  },
  "linkedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LinkedIn Profilimiz için tıklayınız"])},
  "products": {
    "scroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAYFAYI KAYDIRIN"])},
    "more-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha Fazla Bilgi"])},
    "colighting": {
      "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DENİZ ALTI"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coLighting, bulunduğu teknolojik alanda yenilikçi çözümler sunan bir aydınlatma sistemidir."])}
    },
    "codiver": {
      "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OPTİK"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coDiver, sualtında bilgi güvenliği sağlamak ve kaliteli iletişim kurabilmek amacıyla üretilmiş bir haberleşme sistemidir."])}
    },
    "colink": {
      "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OPTİK"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coLink, su üstü alanlarda bilgi güvenliği sağlamak ve kaliteli iletişim kurabilmek amacıyla üretilmiş bir haberleşme sistemidir."])}
    },
    "yetenek": {
      "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EĞİTİM"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YETENEK-12, TÜBİTAK 1511 Öncelikli Alanlar Araştırma Teknoloji Geliştirme ve Yenilik Projeleri Destekleme Programı kapsamında geliştirilmiş bir eğitim kitidir."])}
    }
  },
  "product": {
    "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Araştırma & Geliştirme"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ürünler"])},
    "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha Fazla Bilgi"])},
    "product1": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["su altı aydınlatma ekipmanı coLigthing"])},
      "tag1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optik"])},
      "tag2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su altı"])}
    },
    "product2": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bir su altı iletişim cihazı coDiver"])},
      "tag1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akustik"])},
      "tag2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su altı"])}
    },
    "product3": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["atmosferik elektro-optik iletişim sistemi coDatalink"])},
      "tag1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optik"])},
      "tag2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İletişim"])}
    },
    "product4": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["suyun soğurma yeteneği için bir su altı analizörü coSpectro"])},
      "tag1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optik"])},
      "tag2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teknoloji"])}
    },
    "product5": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["modüler bir elektro optik iletişim cihazı coLink"])},
      "tag1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optik"])},
      "tag2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teknoloji"])}
    },
    "product6": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yenilikçi teknolojik nesil eğitim seti yetenek-12"])},
      "tag1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eğitim"])},
      "tag2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teknoloji"])}
    }
  },
  "projects": {
    "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Araştırma & Geliştirme"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projeler"])},
    "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha Fazla Bilgi"])},
    "project1": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su Altı Optik Spektrum Ölçüm Cihazı"])},
      "tag1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optik"])},
      "tag2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su altı"])}
    },
    "project2": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denizaltılarda Öz Akustik Kestirimi ve Akustik İzleme/Uyarı Sistemi"])},
      "tag1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akustik"])},
      "tag2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su altı"])}
    },
    "project3": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denizaltı-Helikopter Arasında Güvenli Optik Haberleşme Sistemi"])},
      "tag1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optik"])},
      "tag2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İletişim"])}
    }
  },
  "contactus": {
    "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şirketimiz"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bize Ulaşın"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
    "form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İletişim Formu"])},
    "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaj Gönder"])},
    "formEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "formName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsim"])},
    "formMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesajınız"])},
    "fulladdress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mithatpaşa Mah. Rumeli Yolu Cad. Bahçeşehir Üniversitesi Eğitim Binası Blok No: 35/24 İç Kapı No: 6 Eyüpsultan/İSTANBUL"])}
  },
  "error": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404 Hata"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aradığınız sayfa mevcut değil. Taşınmış veya silinmiş olabilir."])},
    "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anasayfaya Dön"])}
  },
  "comingsoon": {
    "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neredeyse Burada"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çok Yakında"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aradığınız sayfa henüz mevcut değil. Lütfen daha sonra tekrar kontrol edin."])},
    "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri Dön"])}
  },
  "about": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BAUMIND (Bahçeşehir Üniversitesi İnovasyon ve Danışmanlık) A.Ş.; 2008 yılında Bahçeşehir Üniversitesi çatısı altında, ağırlıkla savunma sanayi alanında olmak üzere optik, elektro-optik, akustik ve bilgi teknolojileri konularında araştırma ve geliştirme birimi olarak faaliyetlerine başladı."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Türkiye’nin askeri ve sivil alanlarda teknolojik ihtiyaçlarına yönelik ve stratejik hedefleri doğrultusunda yenilikçi çözümler üreten, yurtiçinin yanı sıra yurtdışında da rekabet edebilecek yetkinliğe sahip lider bir firma olma yolunda çalışmaktadır."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dışa bağlılığın azaltılmasına katkıda bulunacak nitelikte yenilikçi çözümler üretmek amacıyla, üniversiteler ve diğer mühendislik firmaları ile stratejik ortaklıklar kurarak, ileri teknolojileri uygulamaları içeren araştırma, inovasyon ve ürün geliştirme faaliyetlerini yürütmektedir."])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hem kamu hem de sivil alanlarda kullanıma uygun sonuçları olan yenilikçi projelerinin çıktılarını ilgili sektörlere lisanslamak, teknolojiyi transfer etmeyi hedefleyen BAUMIND bunlara ek olarak sektördeki kurum ve firmalara uzmanlık alanlarımızda danışmanlık ile eğitim hizmeti de sunmaktadır."])}
  },
  "misyon": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optik, Elektro-Optik, Akustik ve bilgi teknolojileri alanlarında; öncelikle Savunma Sanayi başta olmak üzere askeri/sivil kullanıma yönelik, özgün yenilikçi sürdürülebilir projeler yapan, çözüm sunan ve ürün üreten araştırma geliştirme firması olmak."])}
  },
  "vizyon": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ülkemizin teknolojik ihtiyaçları ve stratejik hedefleri doğrultusunda, bilgi ve teknoloji üreten yurtiçi ve yurtdışında yetkinliğe sahip lider bir firma olmak."])}
  },
  "organization": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teşkilat"])}
  },
  "teams": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üst Yönetim ve Teşkilat"])},
    "niltitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yönetim Kurulu Başkanı BAUMIND "])},
    "niltitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genel Müdür, BUG Lab TEKMER"])},
    "niltitle3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direktör, BAUTTO"])},
    "ferhattitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genel Müdür"])},
    "selcuktitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Ar-Ge Direktörlüğü"])},
    "bulenttitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genel Müdür Yardımcısı"])},
    "suleymantitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistem Entegrasyonu Uzmanı"])},
    "hakantitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmalat Müdürü"])},
    "eraytitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satın Alma Mühendisi"])},
    "saidtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gömülü Yazılım Mühendisi"])},
    "aslititle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kıdemli Ar-Ge Mühendisi"])},
    "fatihtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAD/CAM Tasarım Sorumlusu"])},
    "alpertitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CNC Operatörü"])}
  },
  "project1": {
    "arastirma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARAŞTIRMA & GELİŞTİRME"])},
    "projeler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projeler"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su Altı Optik Spektrum Ölçüm Cihazı"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suyun hidrokarbon dağılımının incelenmesi"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suyun alg kapasitesinin incelenmesi (biyoaktiflik)"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suyun plankton kapasitesinin incelenmesi"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sudaki toprak parçacıklarının erozyon açısından incelenmesi,"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metan incelemesi (biyoaktiflik, fay hatlarının incelenmesi)"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su kalitesinin incelenmesi"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuzluluk oranının tuz cinsine bağlı olarak hassas şekilde incelenmesi"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Türk denizlerinde optik iletkenlik haritalarının çıkarılması"])},
    "amac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projenin amacı; deniz veya göl sularının optik iletkenliğinin, dalga boyuna bağlı olarak ölçülmesidir.Bu ölçüm sonucunda elde edilen grafiğin analiz edilmesi ile bize sağlayabileceği bilgiler ve kullanılabileceği alanlar:"])}
  },
  "project2": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denizaltılarda Öz Akustik Kestirimi ve Akustik İzleme/Uyarı Sistemi"])},
    "baslik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DENİZALTILARDA ÖZ AKUSTİK KESTİRİMİ VE AKUSTİK İZLEME/UYARI SİSTEMİ"])},
    "amac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proje konusu sistemler bir kablosuz iletişim teknolojisi olup, radyo dalgaları yerine ışık huzmesi ve yüksek frekanslı ses dalgaları(ultrasonik) ile iki platform arasında ses aktarımı sağlanmaktadır. Bu sistemlerde ışık kaynağı olarak yüksek frekanslı modüle edilmiş LED veya lazer ışık kaynakları kullanılmaktadır. Elektro-optik sistemler, görüşün yetersiz olduğu durumlarda ve sis vb. hava koşullarında verimsiz olduğu için sistem bu gibi durumlarda akustik haberleşme ile iletişimi sağlayacaktır. "])},
    "bilgi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Savunma Sanayi Başkan’lığı ile Anadolu Deniz İnşaat Kızakları A.Ş. Arasında Mart 2016’da imzalanan Amfibi Gemi (LST) Tedarik Sözleşmesi kapsamında, Teknoloji Kazanım Projesi başlığı altında İTÜNOVA ile sözleşmeye bağlanan “Denizaltılarda Öz Akustik Kestirimi ve Akustik İzleme/Uyarı Sistemi Prototip Geliştirilmesi Projesi Sözleşmesi“ (DAKİS) projesinde üstlenilen komuta kontrol yazılımı geliştirilmesi ve sensör tasarım-imalatı Aralık 2018 yılında başarıyla tamamlanmış ve İTÜNOVA’ ya teslim edilmiştir. "])}
  },
  "project4": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denizaltı-Helikopter Arasında Güvenli Optik Haberleşme Sistemi"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haberleşme menzili: 74,5 metre (test edilebilen 22,5 m sualtı, 52 m suüstü)"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optik olarak LED vasıtası ile iletişim sağlar."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yönlendirilmiş iletişim sağlar"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Duplex iletişim sağlar"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EMI ve RF karıştırmaya dayanıklılık"])},
    "head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DENİZALTI GEMİSİ HELİKOPTER ARASINDA GÜVENLİ ELEKTRO-OPTİK HABERLEŞME SİSTEMİ "])},
    "amac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Savunma Sanayi Başkanlığı ile İstanbul Denizcilik Gemi İnşa Sanayi A.Ş. arasında Nisan 2012 yılında imzalanan Sismik Araştırma Gemisi İnşa Projesi kapsamında, Teknoloji Kazanım Projesi başlığı altında sözleşmeye bağlanan (Ağustos 2012) “Denizaltı ile Helikopter Arasında Güvenli Optik Haberleşme Sistemi”(DEHEGOPHS) projesi 2015 yılında başarıyla tamamlanmıştır."])},
    "head1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(DEHEGOPHS)"])}
  },
  "colight": {
    "giris": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coLighting, bulunduğu teknolojik alanda yenilikçi çözümler sunan bir aydınlatma sistemidir. coLighting, operasyonel su ortamında dalga boyu bazlı zayıflama miktarını ölçerek RGB ışık yoğunluğunu otomatik olarak ayarlayan bir sistemdir."])},
    "teknikbaslik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teknik özellikler"])},
    "teknik1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksik spektrumu tespit eder ve netlik sağlamak için gerekli dalga boylarını otomatik olarak ayarlar"])},
    "teknik2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LED spektrumundaki görünür tüm dalga boylarını RGB LED'ler ile doldurur"])},
    "teknik3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dalga boyu 400nm-870nm aralığındadır"])},
    "teknik4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sudaki beyaz ışık seviyesini ölçerek ayarlama yapar"])},
    "teknik5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistem lineer (LED sürücülü) çalıştığı için gölgeleme problemini ortadan kaldırır"])},
    "teknik6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beyaz ışık noktasını engelleyerek daha net ve detaylı görüntü sağlar"])},
    "part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teknik Parçalar"])},
    "part1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alüminyum Tasarım"])},
    "part2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renk Sensörleri"])},
    "part3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pil Ünitesi"])}
  },
  "codiver": {
    "giris": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coDiver, su altında kestirilemez, karıştırılamaz ve dinlenemez muhabere imkânı sağlayan bir ses iletişim sistemdir. Cihazın mevcut uygulamalara göre en önemli özelliği, kaynaktan çıkan özel modülasyon tekniğine sahip işaretin, sualtındaki diğer dijital sistemler için negatif bir etkisi olan saçılmayı bir avantaj olarak kullanması ve daha geniş bir kapsama alanına imkân sağlayarak kesintisiz iletişime olanak sağlamasıdır. Bu özelliği sayesinde kullanıcılar arasında uzun mesafeli kesintisiz ve güvenli iletişim sağlamaktadır."])},
    "teknikbaslik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teknik özellikler"])},
    "teknik1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full ve Half Dupleks Sesli Muhabere İmkanı"])},
    "teknik2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP68 - 60 metre sızdırmazlık"])},
    "teknik3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MIL-STD-810G Metot 501.5 (I-II), Metot 502.5 (I-II), Metot 507.5 (II) çevresel koşul şartlarına dayanıklı"])},
    "teknik4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MIL-STD-461G RE102 (b/c), RE103 (a/b) EMI EMC şartlarına dayanıklı"])},
    "teknik5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Dupleks iletişim modunda 2 saat şarj ile 5 saat operasyonel kullanım"])},
    "teknik6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Half Dupleks iletişim modunda 2 saat şarj ile 15 saat operasyonel kullanım"])},
    "teknik7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6.000 mAh harici batarya ünitesi"])},
    "teknik8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sualtı görüş mesafesinin iki katına kadar iletişim mesafesi"])},
    "teknik9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kestirilemez, Dinlenemez ve Karıştırılamaz iletişim imkanı"])},
    "teknik10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export Lisansına tabi olmayan malzemelerden %75 yerli ve milli olarak üretilmiştir"])}
  },
  "colink": {
    "giris": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atmosferik ortamlarda kullanılmak üzere tasarlanmış olan coLink, göze zarar vermeyen elektro-optik teknoloji ile iletişimde bilgi güvenliği sağlar. Avcı, araştırmacı ve gözlemciler için, tüm dış alan aktivitelerinde, sabit platformlarda tripod ile kullanımda, gizli operasyonlarda güvenli iletişimde, özel kuvvet personelleri arası iletişimde, sabit askeri platformlar arası mobil ses/veri iletişimde kullanılmaktadır."])},
    "teknikbaslik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teknik özellikler"])},
    "teknik1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full ve Half Dupleks Sesli Muhabere İmkanı"])},
    "teknik2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP23 sızdırmazlık"])},
    "teknik3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MIL-STD-810G Metot 501.5 (I-II), Metot 502.5 (I-II), Metot 507.5 (II) çevresel koşul şartlarına dayanıklı"])},
    "teknik4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MIL-STD-461G RE102 (b/c), RE103 (a/b) EMI EMC dayanıklı"])},
    "teknik5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Dupleks iletişim modunda 2 saat şarj ile 2 saat operasyonel kullanım"])},
    "teknik6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Half Dupleks iletişim modunda 2 saat şarj ile 8 saat operasyonel kullanım"])},
    "teknik7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6.000mAh harici batarya ünitesi"])},
    "teknik8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1400 metre operasyonel iletişim mesafesi"])},
    "teknik9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kestirilemez, Dinlenemez ve Karıştırılamaz iletişim imkanı"])},
    "teknik10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export Lisansına tabi olmayan malzemelerden %75 yerli ve milli olarak üretilmiştir"])}
  }
}